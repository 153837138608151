<template lang="pug">
  include ../plugins/pug/template

  header(class="header" :class="{ 'header--active': isHeaderActive }")
    div.container
      nav.menu
        router-link.logo(:to="{ name: 'HomePage' }")
          img(
            :src="Logo"
            alt="logo"
          )
        div.menu__inner(
          :class="{ 'menu-show': isMenuOpen }"
          @click="isMenuOpen = !isMenuOpen"
        )
          ul
            li(
              v-for="(nav, index) in navs"
              :key="index"
              @click="scrollToTop"
            )
              router-link(:to="{ name: nav.route }") {{ nav.title }}
          v-icon(
            tag="a"
            href="https://docs.easypon.in/how-to-install-easypon"
            target="_blank"
            dense
          ).menu__quest.docs-link mdi-help-circle-outline
          div.menu__btns
            a(
              href="https://cabinet.easypon.in/login"
              target="_blank"
            ).btn-blank {{ $t('btn.signIn') }}
            a(
              href="https://cabinet.easypon.in/registration"
              target="_blank"
            ).btn-fill {{ $t('btn.register') }}
          v-menu(offset-y)
            template(v-slot:activator="{ on, attrs }")
              div(
                v-bind="attrs"
                v-on="on"
              ).flag__btn.d-flex.flex-row.align-center
                img(
                  v-on="on"
                  :src="flags[$i18n.locale]"
                  :alt="`flag-${ $i18n.locale }`"
                ).flag__icon
                v-icon
                  | mdi-chevron-down
            v-list.flag__list
              v-list-item(
                v-for="(item, index) in locales"
                :key="index"
              ).d-flex.flex-row.align-center
                a(:href="$route.path.replace($route.params.locale, item.route)").d-flex.flex-row.align-center
                  span {{ item.name }}
                  img(
                    :src="item.flag"
                    :alt="`flag-${ item.name }`"
                  ).flag__icon
        button.menu__toggle(
          @click="isMenuOpen = !isMenuOpen"
          :class="{ 'toggle-active': isMenuOpen }"
        )
          img(
            :src="MenuOut"
            alt="out"
          )
          img(
            :src="MenuIn"
            alt="in"
          )
</template>

<script>
import en from '@/assets/images/eng.png'
import uk from '@/assets/images/ukr.png'
import hr from '@/assets/images/hrv.png'
import Logo from '@/assets/images/logo.svg'
import MenuOut from '@/assets/images/out.svg'
import MenuIn from '@/assets/images/in.svg'

const flags = { en, uk, hr }

export default {
  name: 'HeaderBlock',
  data () {
    return {
      flags,
      Logo,
      MenuOut,
      MenuIn,
      isMenuOpen: false,
      isHeaderActive: false,
      navs: [
        {
          title: this.$t('nav.home'),
          route: 'HomePage'
        },
        {
          title: this.$t('nav.functions'),
          route: 'ToolsPage'
        },
        // {
        //   title: this.$t('nav.pricing'),
        //   route: 'PricesPage'
        // },
        {
          title: this.$t('nav.contactUs'),
          route: 'ContactPage'
        },
        {
          title: this.$t('nav.blog'),
          route: 'BlogPage'
        },
        {
          title: this.$t('nav.demo'),
          route: 'DemoPage'
        }
      ]
    }
  },
  computed: {
    locales () {
      const locales = this.$i18n.locales
      return locales.map(locale => ({
        name: this.$t(locale.code),
        value: locale.code,
        route: locale.route,
        flag: this.flags[locale.code]
      }))
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    handleScroll () {
      const scrollPos = window.scrollY
      this.isHeaderActive = scrollPos > 10
    }
  }
}
</script>
